<template>
  <v-container fluid no-gutters app class="pa-0" :style="themeColors">
    <v-app-bar elevation="2" color="light">
      <v-app-bar-nav-icon
        data-testid="app-bar-nav-icon"
        @click.stop="drawer = !drawer"
        color="primary"
        :loading="loading"
      ></v-app-bar-nav-icon>

      <img
        :src="`/img/${getLogos.appBar}`"
        alt="Logotipo da empresa"
        style="max-width: 188px; max-height: 40px"
      />

      <AppBarEnvLabel
        v-if="configsEnv === 'homol' || configsEnv === 'dev'"
        :configsEnv="configsEnv"
      />

      <v-spacer></v-spacer>

      <BgMenu></BgMenu>

      <v-menu
        v-if="currentClientModule"
        bottom
        offset-y
        style="z-index: 1000 !important"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            v-bind="attrs"
            v-on="on"
            class="mr-2"
            :loading="loading"
            color="primary"
          >
            <v-icon color="primary" class="mr-2">{{
              currentClientModule.icon
            }}</v-icon>
            <span>{{ currentClientModule.name }}</span>
          </v-btn>
        </template>

        <v-list style="overflow-y: auto">
          <v-list-item-group active-class="primary--text text--accent-4">
            <a
              v-for="clientModule in getClientModules"
              :key="clientModule.name"
              class="text-decoration-none"
              :href="navigateClientModules(clientModule.subdomain)"
            >
              <v-layout align-center>
                <v-flex xs10>
                  <v-list-item
                    :class="
                      clientModule.subdomain === currentClientModule.subdomain
                        ? 'v-list-item--active'
                        : ''
                    "
                  >
                    <v-list-item-icon>
                      <v-icon
                        v-text="clientModule.icon"
                        color="primary"
                      ></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="clientModule.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-flex>
                <v-flex xs2>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-layout justify-center>
                        <a
                          :href="navigateClientModules(clientModule.subdomain)"
                          target="_blank"
                          class="text-decoration-none"
                          v-on="on"
                        >
                          <v-icon
                            class="px-2"
                            color="primary"
                            style="margin-left: -8px !important"
                            small
                            >mdi-open-in-new</v-icon
                          >
                        </a>
                      </v-layout>
                    </template>
                    <span>Abrir em nova aba</span>
                  </v-tooltip>
                </v-flex>
              </v-layout>
            </a>
          </v-list-item-group>
        </v-list>
      </v-menu>

      <v-progress-circular
        v-else
        indeterminate
        size="24"
        width="3"
        color="primary"
        class="ml-2 mr-4"
      ></v-progress-circular>

      <v-menu bottom offset-y style="z-index: 1000">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" class="mr-4">
            <v-badge
              avatar
              bottom
              :color="sessionBadgeColor"
              offset-x="18"
              offset-y="18"
            >
              <template v-if="getUser">
                <v-avatar v-if="getUser.photo">
                  <v-img :src="getUser.photo" :aspect-ratio="1 / 1">
                    <template v-slot:placeholder>
                      <v-skeleton-loader type="avatar"></v-skeleton-loader>
                    </template>
                  </v-img>
                </v-avatar>
                <v-avatar v-else>
                  <v-icon color="primary" class="text-h3"
                    >mdi-account-circle</v-icon
                  >
                </v-avatar>
              </template>
              <v-avatar v-else>
                <v-icon color="primary" class="text-h3"
                  >mdi-account-circle</v-icon
                >
              </v-avatar>
            </v-badge>
          </v-btn>
        </template>

        <v-sheet max-width="350px">
          <v-list>
            <v-list-item-group>
              <v-list-item @click="$router.push('user-profile')">
                <v-list-item-icon class="mr-4">
                  <template v-if="getUser">
                    <v-avatar v-if="getUser.photo">
                      <v-img
                        :src="getUser.photo"
                        :alt="'Foto de ' + getUser.name"
                        :aspect-ratio="1 / 1"
                      />
                    </v-avatar>
                    <v-icon v-else color="primary" x-large
                      >mdi-account-circle</v-icon
                    >
                  </template>
                  <v-icon v-else color="primary" x-large
                    >mdi-account-circle</v-icon
                  >
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title class="text-h6">{{
                    getUser ? getUser.name : ""
                  }}</v-list-item-title>
                  <v-list-item-title class="text-caption">{{
                    getUser ? getUser.email : ""
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider></v-divider>

              <v-row class="py-2 px-4">
                <v-col class="d-flex align-center">
                  <v-icon small color="primary" class="mr-2">mdi-clock</v-icon>
                  <span class="text-caption">{{ `${tokenExp()}` }}</span>
                  <v-spacer></v-spacer>
                  <v-btn x-small text color="primary" @click="renewSession()">
                    Renovar
                  </v-btn>
                </v-col>
              </v-row>

              <v-divider></v-divider>

              <v-list-item @click="endSession()">
                <v-list-item-content>
                  <v-list-item-title>Sair</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-sheet>
      </v-menu>

      <v-divider vertical class="mr-3 ml-3" inset></v-divider>

      <a
        href="https://znap.com.br/"
        target="_blank"
        style="text-decoration: none"
        class="d-flex align-center"
      >
        <img
          src="/img/znap-app-bar.png"
          alt="Logotipo da ZNAP Technologies"
          style="max-width: 120px"
        />
      </a>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      temporary
      app
      :style="navigationDrawerStyle"
    >
      <v-container fluid fill-height class="pa-0 ma-0">
        <v-layout column>
          <div class="d-flex">
            <v-app-bar-nav-icon
              @click.stop="drawer = !drawer"
              color="primary"
              :loading="loading"
              class="my-2 ml-1"
              min-width="48px"
              min-height="48px"
            ></v-app-bar-nav-icon>

            <div max-width="300" max-height="51">
              <img
                style="margin-top: 11px; max-width: 188px; max-height: 80px"
                :src="`/img/${getLogos.appBar}`"
                alt="Logomarca"
              />
            </div>
          </div>

          <v-divider></v-divider>

          <v-list>
            <div v-for="(item, index) in this.getMenu" :key="item.groupCode">
              <v-list-item-group v-model="selectionMenu">
                <v-list-item
                  data-testid="group-menu"
                  @click="setGroupMenu(item, index)"
                  :class="{ 'v-list-item--active': selectedGroupMenu(item) }"
                >
                  <v-list-item-icon>
                    <v-icon color="primary">{{ item.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title>{{ item.groupName }}</v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-icon>
                    <v-icon>{{
                      item.expand ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>

              <v-expand-transition>
                <v-list-item-group
                  v-show="item.expand"
                  class="ml-14"
                  v-model="selectionSubMenu"
                  multiple
                >
                  <a
                    data-testid="submenu"
                    v-for="menu in item.menus"
                    :key="menu.transactionCode"
                    :href="menu.frontend_route_path"
                    class="text-decoration-none"
                  >
                    <v-layout align-center>
                      <v-flex xs10>
                        <v-list-item
                          @click="navigateMenu(menu)"
                          @click.prevent="
                            $nextTick(() => {
                              selectionSubMenu = [];
                            })
                          "
                        >
                          <v-list-item-icon
                            v-if="$route.path === menu.frontend_route_path"
                            class="mx-0"
                          >
                            <v-icon
                              color="primary"
                              style="margin-left: -8px !important"
                              >mdi-menu-right</v-icon
                            >
                          </v-list-item-icon>

                          <v-list-item-content>
                            <v-list-item-title
                              class="text-subtitle-2 font-weight-regular"
                            >
                              {{ menu.label }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-flex>
                      <v-flex xs2>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on }">
                            <v-layout justify-center>
                              <a
                                :href="menu.frontend_route_path"
                                target="_blank"
                                class="text-decoration-none"
                                v-on="on"
                              >
                                <v-icon
                                  class="px-2"
                                  color="primary"
                                  style="margin-left: -8px !important"
                                  small
                                  >mdi-open-in-new</v-icon
                                >
                              </a>
                            </v-layout>
                          </template>
                          <span>Abrir em nova aba</span>
                        </v-tooltip>
                      </v-flex>
                    </v-layout>
                  </a>
                </v-list-item-group>
              </v-expand-transition>
            </div>
          </v-list>
          <v-spacer></v-spacer>
          <v-footer color="white">
            <v-layout align-center justify-center>
              <a
                :href="releaseURL"
                target="_blank"
                class="text-decoration-none"
              >
                <p class="text-caption text-center grey--text text--darken-3">
                  v. {{ appVersion }}
                </p>
              </a>
            </v-layout>
          </v-footer>
        </v-layout>
      </v-container>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import moment from "moment";
import { version } from "../../package";
import configs from "@/configs";
import setModuleRoute from "@/helpers/setModuleRoute";
import BgMenu from "@/components/BgMenu.vue";
import AppBarEnvLabel from "@/components/AppBarEnvLabel";

export default {
  name: "AppBar",

  components: {
    BgMenu,
    AppBarEnvLabel,
  },

  props: {
    loading: { type: Boolean, required: true },
  },

  data() {
    return {
      drawer: false,
      group: null,

      selectionMenu: [],
      selectionGroupMenu: [],
      selectionSubMenu: [],

      // session exp
      now: new Date(),
      sessionBadgeColor: "success",
      renewLoading: true,

      appVersion: version,
    };
  },

  computed: {
    ...mapGetters("auth", [
      "getUser",
      "getMenu",
      "getTokenExp",
      "getClientModules",
      "getHash",
      "getEnableSessionExpiredDialog",
    ]),
    ...mapGetters("theme", ["getLogos"]),

    navigationDrawerStyle() {
      return { width: this.drawer ? "356px" : "0px" };
    },

    themeColors() {
      return {
        "--primary-color": `${this.$vuetify.theme.themes.light.primary}`,
      };
    },

    currentClientModule() {
      const znapDomain = ".znaptech.com";
      const subdomain = `.${configs.moduleName}${znapDomain}`;
      return this.getClientModules.find((i) => i.subdomain === subdomain);
    },

    releaseURL() {
      const release = version;
      const url = `https://github.com/Znap-Technologies/${configs.system.toLowerCase()}-${
        configs.moduleName
      }-releases/releases/tag/${release}`;
      return url;
    },

    configsEnv() {
      return configs ? configs.env : null;
    },
  },

  watch: {
    // Observa mudanças na rota para ajustar o drawer conforme necessário
    '$route'(to) {
      this.openDrawerBasedOnRoute(to.path);
    }
  },

  mounted() {
    setInterval(() => {
      this.now = new Date();
    }, 1000);
    this.openDrawerSmoothly()
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 'vm' é uma referência à instância do Vue
      vm.openDrawerBasedOnRoute(to.path);
    });
  },

  beforeUnmount() {
    clearInterval();
  },

  methods: {
    ...mapMutations("auth", ["setSessionExpiredDialog"]),
    ...mapActions("auth", ["logout", "hashAuthenticate"]),

    openDrawerBasedOnRoute(path) {
      if (path === '/') {
        this.$nextTick(() => {
          setTimeout(() => {
            this.drawer = true;
          }, 100); // Um pequeno delay para garantir uma transição suave
        });
      }
    },

    openDrawerSmoothly() {
        if (this.$route.path === '/') {
            // Aguarda a montagem do componente para iniciar a transição
            this.$nextTick(() => {
              setTimeout(() => {
                this.drawer = true;
              }, 100); // Um pequeno delay para garantir uma transição suave
            });
        }
    },

    tokenExp() {
      let tokenExp = "";

      if (this.getTokenExp && !this.isValidToken(this.getTokenExp)) {
        if (this.getEnableSessionExpiredDialog)
          this.setSessionExpiredDialog(true);
        return (tokenExp = "Sessão expirada");
      }

      let now = moment(this.now, "hh:mm:ss");
      let exp = moment.unix(this.getTokenExp, "hh:mm:ss");
      let duration = moment.duration(exp.diff(now))._data;

      let hours =
        duration.hours.toString().length < 2
          ? `0${duration.hours}`
          : `${duration.hours}`;
      let minutes =
        duration.minutes.toString().length < 2
          ? `0${duration.minutes}`
          : `${duration.minutes}`;
      let seconds =
        duration.seconds.toString().length < 2
          ? `0${duration.seconds}`
          : `${duration.seconds}`;
      let time = `${hours}:${minutes}:${seconds}`;

      this.setSessionBadgeColor(hours, minutes);

      tokenExp = `Sessão expira em ${time}`;

      let timeCompare = time.replaceAll(":", "");
      timeCompare = parseInt(timeCompare, 10);
      if (duration.hours < 1 && duration.minutes < 5) {
        if (timeCompare > 1 && this.renewLoading) {
          this.renewLoading = false;
          this.renewSession().then(() => {
            if (duration.hours < 1 && duration.minutes < 5) {
              this.renewLoading = true;
            }
          });
        }
      }

      return tokenExp;
    },

    setSessionBadgeColor(hours, minutes) {
      if (!hours || !minutes) {
        return (this.sessionBadgeColor = "error");
      }

      let h = parseInt(hours);
      let m = parseInt(minutes);

      if (h === 0) {
        if (m >= 10) {
          this.sessionBadgeColor = "success";
        } else if (m < 10 && m > 5) {
          this.sessionBadgeColor = "warning";
        } else if (m < 5) {
          this.sessionBadgeColor = "error";
        }
      } else {
        this.sessionBadgeColor = "success";
      }
    },

    isValidToken(tokenExp) {
      let now = Math.floor(Date.now() / 1000);
      return now - tokenExp <= 0;
    },

    async renewSession() {
      let hash = this.getHash;

      const auth = await this.hashAuthenticate(hash);
      if (auth === "success") {
        this.$toast.success("Sessão renovada com sucesso");
      } else {
        let err = auth;
        this.$fnError(err);
      }
    },

    closeAllExpandedMenus() {
      this.getMenu.forEach((menu) => {
        menu.expand = false;
      });

      this.selectionGroupMenu = [];
    },

    setGroupMenu(item) {
      this.$nextTick(() => {
        this.selectionMenu = [];
      });

      let i = this.selectionGroupMenu.indexOf(item);

      if (i === -1) {
        item.expand = true;
        this.selectionGroupMenu.push(item);
      } else {
        item.expand = false;
        this.selectionGroupMenu.splice(i, 1);
      }
    },

    selectedGroupMenu(item) {
      let active = false;
      item.menus.forEach((menu) => {
        if (this.$route.path === menu.frontend_route_path) {
          active = true;
        }
      });

      return active;
    },

    navigateMenu(menu) {
      if (this.$route.path !== menu.frontend_route_path) {
        this.$router.push(menu.frontend_route_path);
        this.closeAllExpandedMenus();
      }
    },

    navigateClientModules(subdomain) {
      const localEnv = window.location.href.includes(":80");
      const url = setModuleRoute(subdomain, localEnv, this.getHash);
      return url;
    },

    async endSession() {
      return this.logout();
    },

    async getNotifications() {
      try {
        const payload = {
          filter: {
            conditions: [
              {
                AndOr: "AND",
                column: "id_user",
                operator: "=",
                value: this.getUser.id_user,
              },
            ],
          },
        };

        const res = await this.$http.post(
          this.$ipMessage + "notification/list",
          { ...payload }
        );

        if (res) {
          this.notifications = res.data.rows;
          this.notificationsNew = [];
          this.notifications.forEach((notification) => {
            notification.notification_status === 0
              ? this.notificationsNew.push(notification)
              : false;
          });
        }
      } catch (err) {
        this.$fnError(err);
      }
    },

    async tagAsRead(notification) {
      try {
        const payload = {
          description: notification.description,
          id_user: this.getUser.id_user,
          link: notification.link,
          notification_status: 1,
          id_module: 5,
        };

        const res = await this.$http.put(
          this.$ipMessage + "notification/edit/" + notification.id_notification,
          { ...payload }
        );

        if (res) {
          this.getNotifications();
        }
      } catch (err) {
        this.$fnError(err);
      }
    },

    async deleteNotifications(id) {
      try {
        const res = await this.$http.delete(
          this.$ipMessage + "notification/delete/" + id
        );

        if (res) {
          this.getNotifications();
        }
      } catch (err) {
        this.$fnError(err);
      }
    },
  },
};
</script>

<style scoped>
.scroll {
  overflow-y: scroll;
}

.v-list-item--active {
  color: var(--primary-color) !important;
}

.v-navigation-drawer {
  will-change: initial;
  transform: none !important;
  transition-property: "width";
  z-index: 1500 !important;
}
</style>
